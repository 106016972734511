var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"exam-settings-section\">\n\n    <form id=\"update-username-form\" class=\"form\"></form>\n\n    <h2 data-i18n=\"sa.settings.authorized_applications\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"sa.settings.authorized_applications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":56},"end":{"line":6,"column":6}}}))
    + "</h2>\n    <table id=\"oauth-clients\" class=\"oauth-client-table\">\n        <thead>\n        <tr>\n            <th>Sovellus</th>\n            <th>Oikeudet</th>\n            <th>Poista valtuutus</th>\n        </tr>\n        </thead>\n        <tbody>\n\n        </tbody>\n    </table>\n\n</div>\n";
},"useData":true});