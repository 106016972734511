var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"notification-popup\">\n  <h4 data-i18n=\"sa.is_exam_ready_for_download\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.is_exam_ready_for_download",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":48},"end":{"line":2,"column":85}}}))
    + "</h4>\n  <p data-i18n=\"sa.download_locks_exam\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.download_locks_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":70}}}))
    + "</p>\n  <p><span data-i18n=\"sa.exams_code\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exams_code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":37},"end":{"line":4,"column":58}}}))
    + "</span><span class=\"code\"></span></p>\n  <div class=\"button-container\">\n    <button id=\"js-cancel-download\" class=\"action-link\" data-i18n=\"sa.cancel\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":78},"end":{"line":6,"column":95}}}))
    + "</button>\n    <button id=\"js-proceed-download\" class=\"action-link default-action\" data-i18n=\"sa.download\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.download",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":96},"end":{"line":7,"column":116}}}))
    + "</button>\n  </div>\n</div>\n";
},"useData":true});