import * as Bacon from 'baconjs'
import $ from 'jquery'
import * as i18n from './i18n'
import * as i18nHelpers from './i18n-hbs-helpers'

const changeLang = lang => {
  const updateRadio = () => $(`input[name="language"][value="${lang}"]`).prop('checked', true)
  i18nHelpers.changeLanguage(lang, updateRadio)
}

const languageChangedE = $('body')
  .asEventStream('click', 'input[name="language"]')
  .doAction(e => e.preventDefault())
  .map(e => e.target.value)

const i18nInit = Bacon.fromCallback(i18n.init)

export const languageProperty = Bacon.mergeAll(i18nInit, languageChangedE)
  .filter(value => value)
  .toProperty()

languageProperty.onValue(changeLang)

export function ensureSetupAndShowCurrentLanguage(callback) {
  languageProperty.first().onValue(changeLang)

  if (callback) {
    languageProperty.first().onValue(callback)
  }
}
