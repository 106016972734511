var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 data-i18n=\"sa.grading_tab\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.grading_tab",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":53}}}))
    + "</h1>\n\n<div id=\"answers-upload\"></div>\n\n<h3><span data-i18n=\"sa.held_exams\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.held_exams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":57}}}))
    + "</span> (<span class=\"js-held-exam-total\"></span>) </h3>\n\n<input type=\"checkbox\" id=\"show-deleted-held-exams\" class=\"show-deleted-input\"/> <label for=\"show-deleted-held-exams\" class=\"show-deleted-label\" data-i18n=\"sa.show_deleted\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.show_deleted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":173},"end":{"line":7,"column":196}}}))
    + "</label>\n<table id=\"held-exams\">\n  <thead>\n    <th data-i18n=\"sa.uploaded\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.uploaded",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":32},"end":{"line":10,"column":51}}}))
    + "</th>\n    <th data-i18n=\"sa.held_exam_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.held_exam_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":38},"end":{"line":11,"column":63}}}))
    + "</th>\n    <th data-i18n=\"sa.students\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.students",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":51}}}))
    + "</th>\n    <th data-i18n=\"sa.held_answers\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.held_answers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":36},"end":{"line":13,"column":59}}}))
    + "</th>\n    <th data-i18n=\"sa.held_graded\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.held_graded",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":35},"end":{"line":14,"column":57}}}))
    + "</th>\n    <th></th>\n    <th></th>\n    <th></th>\n  </thead>\n  <tbody>\n  </tbody>\n</table>\n";
},"useData":true});