var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"exam-import-upload-panel\" name=\"exam-import\" method=\"POST\" action=\"\">\n  <div id=\"exam-import-button-wrapper\">\n    <button class=\"upload-button medium-sa-button\" data-i18n=\"sa.import_exam\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.import_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":78},"end":{"line":3,"column":100}}}))
    + "</button>\n    <div class=\"upload-takes-long-container\">\n      <span class=\"upload-spinner\"></span>\n      <span class=\"notification-text\" data-i18n=\"sa.import_takes_time\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.import_takes_time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":71},"end":{"line":6,"column":99}}}))
    + "</span>\n    </div>\n    <div id=\"exam-import-error\" class=\"upload-error error-notice\"></div>\n  </div>\n  <input class=\"upload-input\" type=\"file\" name=\"examZip\" accept=\".zip\"/>\n</form>\n";
},"useData":true});