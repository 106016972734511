var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"answers-upload-panel\" name=\"answers-upload\" method=\"POST\" action=\"\">\n  <div class=\"instructions-wrapper\">\n    <p data-i18n=\"sa.import_answers_instructions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.import_answers_instructions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":50},"end":{"line":3,"column":88}}}))
    + "</p>\n  </div>\n  <div class=\"answers-upload-button-wrapper\">\n    <label for=\"examUpload\">\n      <button tabindex=\"0\" class=\"upload-button sa-button-big\" data-i18n=\"sa.import_answers\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.import_answers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":93},"end":{"line":9,"column":10}}}))
    + "</button>\n    </label>\n    <div class=\"upload-takes-long-container\">\n      <span class=\"upload-spinner\"></span>\n      <span class=\"notification-text\" data-i18n=\"sa.answer_upload_takes_time\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.answer_upload_takes_time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":78},"end":{"line":13,"column":113}}}))
    + "</span>\n    </div>\n  </div>\n  <input id=\"examUpload\" class=\"upload-input\" type=\"file\" name=\"examUpload\" accept=\".meb\" />\n  <div class=\"upload-error error-notice\"></div>\n</form>";
},"useData":true});