var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content\">\n  <div class=\"footer-column\">\n    <div>\n        <h5 data-i18n=\"footer.contact_details\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"footer.contact_details",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":47},"end":{"line":4,"column":77}}}))
    + "</h5>\n        <p><a href=\"mailto:abitti@ylioppilastutkinto.fi\">abitti@ylioppilastutkinto.fi</a>\n        <p><a href=\"tel:+358295338280\" data-i18n=\"footer.phone\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"footer.phone",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":64},"end":{"line":6,"column":84}}}))
    + "</a></p>\n    </div>\n  </div>\n  <div class=\"footer-column wide-column\">\n      <p><a class=\"with-logo\" href=\"https://ylioppilastutkinto.fi/\"><span data-i18n=\"footer.meb\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"footer.meb",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":97},"end":{"line":10,"column":115}}}))
    + "</span></a></p>\n      <p><a class=\"with-logo\" href=\"https://www.abitti.fi/fi/paivitykset/\"><span data-i18n=\"footer.updates\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"footer.updates",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":108},"end":{"line":11,"column":130}}}))
    + "</span></a></p>\n      <p><a class=\"with-logo\" href=\"https://www.abitti.fi/fi/kayttoehdot/\"><span data-i18n=\"footer.digabi_terms\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"footer.digabi_terms",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":113},"end":{"line":12,"column":140}}}))
    + "</span></a></p>\n  </div>\n  <div class=\"footer-column\">\n      <p data-i18n=\"footer.copy\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"footer.copy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":33},"end":{"line":15,"column":52}}}))
    + "</p>\n  </div>\n</div>\n";
},"useData":true});