var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "locked";
},"3":function(container,depth0,helpers,partials,data) {
    return "invalid";
},"5":function(container,depth0,helpers,partials,data) {
    return "<div class=\"attachment-wrapper\"><i class=\"fa fa-paperclip attachment-icon\"></i></div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"locked") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":11},"end":{"line":1,"column":38}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"valid") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":39},"end":{"line":1,"column":74}}})) != null ? stack1 : "")
    + " deleted-row\" data-exam-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"examUuid") || (depth0 != null ? lookupProperty(depth0,"examUuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"examUuid","hash":{},"data":data,"loc":{"start":{"line":1,"column":104},"end":{"line":1,"column":116}}}) : helper)))
    + "\">\n  <td><div class=\"exam-date-wrapper\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"creationDateStr") || (depth0 != null ? lookupProperty(depth0,"creationDateStr") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"creationDateStr","hash":{},"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":56}}}) : helper)))
    + "</div></td>\n  <td>\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAttachments") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":118}}})) != null ? stack1 : "")
    + "\n      <span class=\"name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":25},"end":{"line":5,"column":34}}}) : helper)))
    + "</span>\n  </td>\n  <td></td>\n  <td>\n      <button class=\"js-undelete-exam-button sa-button-table\" data-exam-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"examUuid") || (depth0 != null ? lookupProperty(depth0,"examUuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"examUuid","hash":{},"data":data,"loc":{"start":{"line":9,"column":78},"end":{"line":9,"column":90}}}) : helper)))
    + "\" type=\"button\" data-i18n=\"sa.undelete\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"sa.undelete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":130},"end":{"line":9,"column":149}}}))
    + "</button>\n  </td>\n</tr>\n\n";
},"useData":true});