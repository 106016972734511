var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "locked";
},"3":function(container,depth0,helpers,partials,data) {
    return "invalid";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span class=\"bertta\"></span>\n    <div class=\"exam-date-wrapper\">";
},"7":function(container,depth0,helpers,partials,data) {
    return "<div class=\"attachment-wrapper\"><i class=\"fa fa-paperclip attachment-icon\"></i></div>";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/school/preview/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"examUuid") || (depth0 != null ? lookupProperty(depth0,"examUuid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"examUuid","hash":{},"data":data,"loc":{"start":{"line":8,"column":42},"end":{"line":8,"column":54}}}) : helper)))
    + "\" class=\"preview\" target=\"_blank\">";
},"11":function(container,depth0,helpers,partials,data) {
    return " link";
},"13":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <form class=\"exam-download-form\" method=\"GET\" action=\"/exam-api/exams/"
    + alias2(((helper = (helper = lookupProperty(helpers,"examUuid") || (depth0 != null ? lookupProperty(depth0,"examUuid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"examUuid","hash":{},"data":data,"loc":{"start":{"line":14,"column":76},"end":{"line":14,"column":88}}}) : helper)))
    + "/exam-meb\">\n        <button class=\"download-exam-button sa-button-table\" type=\"button\" data-i18n=\"sa.download_exam\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"valid") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":104},"end":{"line":15,"column":140}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.download_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":141},"end":{"line":15,"column":165}}}))
    + "</button>\n      </form>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span data-i18n=\"sa.old_format_label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"sa.old_format_label",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":44},"end":{"line":18,"column":71}}}))
    + "</span>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "bertta-button";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <a href=\"/school/bertta/"
    + alias2(((helper = (helper = lookupProperty(helpers,"examUuid") || (depth0 != null ? lookupProperty(depth0,"examUuid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"examUuid","hash":{},"data":data,"loc":{"start":{"line":35,"column":42},"end":{"line":35,"column":54}}}) : helper)))
    + "\" data-i18n=\"sa.edit_exam\" class=\"edit-link\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.edit_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":99},"end":{"line":35,"column":119}}}))
    + "</a>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span data-i18n=\"sa.edit_exam\" class=\"edit-link disabled\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"sa.edit_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":70},"end":{"line":38,"column":90}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isXml") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":12},"end":{"line":41,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isXml") : depth0),{"name":"unless","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":44,"column":23}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <span class=\"disabled-modify-exam-button-tooltip\" data-i18n=\"lapa.xml_exam_not_editable\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"lapa.xml_exam_not_editable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":103},"end":{"line":40,"column":137}}}))
    + "</span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <span class=\"disabled-modify-exam-button-tooltip\" data-i18n=\"sa.edit_exam_disabled_tooltip\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"sa.edit_exam_disabled_tooltip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":106},"end":{"line":43,"column":143}}}))
    + "</span>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"exam-error-text\" data-i18n=\"sa.exam_incomplete\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"sa.exam_incomplete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":82},"end":{"line":48,"column":109}}}))
    + "</span>";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isXml") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":12},"end":{"line":57,"column":19}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button class=\"action-link reveal-code-button\" type=\"button\" data-i18n=\"sa.show_code\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.show_code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":98},"end":{"line":55,"column":118}}}))
    + "</button>\n          <span class=\"exam-code\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"password") || (depth0 != null ? lookupProperty(depth0,"password") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"password","hash":{},"data":data,"loc":{"start":{"line":56,"column":34},"end":{"line":56,"column":46}}}) : helper)))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"locked") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":11},"end":{"line":1,"column":38}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"valid") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":39},"end":{"line":1,"column":74}}})) != null ? stack1 : "")
    + " title-row\" data-exam-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"examUuid") || (depth0 != null ? lookupProperty(depth0,"examUuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"examUuid","hash":{},"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":114}}}) : helper)))
    + "\">\n  <td class=\"exam-date\">\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isXml") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":4,"column":42}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"creationDateStr") || (depth0 != null ? lookupProperty(depth0,"creationDateStr") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"creationDateStr","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":61}}}) : helper)))
    + "</div>\n  </td>\n  <td>\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAttachments") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":118}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isXml") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":95}}})) != null ? stack1 : "")
    + "\n        <span class=\"name"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isXml") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":25},"end":{"line":9,"column":50}}})) != null ? stack1 : "")
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":9,"column":52},"end":{"line":9,"column":61}}}) : helper)))
    + "</span><i class=\"fa fa-share-square-o\"></i>\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isXml") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":28}}})) != null ? stack1 : "")
    + "\n  </td>\n  <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isXml") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "  </td>\n  <td>\n    <form class=\"exam-export-form\" method=\"GET\" action=\"/exam-api/exams/"
    + alias4(((helper = (helper = lookupProperty(helpers,"examUuid") || (depth0 != null ? lookupProperty(depth0,"examUuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"examUuid","hash":{},"data":data,"loc":{"start":{"line":22,"column":72},"end":{"line":22,"column":84}}}) : helper)))
    + "/export-exam\">\n      <button class=\"export-exam-button sa-button-table\" data-i18n=\"sa.export_exam\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"valid") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":84},"end":{"line":23,"column":120}}})) != null ? stack1 : "")
    + ">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"sa.export_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":121},"end":{"line":23,"column":143}}}))
    + "</button>\n    </form>\n  </td>\n  <td>\n    <button class=\"js-add-xml-exam-event-copy sa-button-table "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isXml") : depth0),{"name":"unless","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":62},"end":{"line":27,"column":103}}})) != null ? stack1 : "")
    + "\" data-exam-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"examUuid") || (depth0 != null ? lookupProperty(depth0,"examUuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"examUuid","hash":{},"data":data,"loc":{"start":{"line":27,"column":121},"end":{"line":27,"column":135}}}) : helper)))
    + "\" data-i18n=\"sa.copy_exam\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"sa.copy_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":162},"end":{"line":27,"column":182}}}))
    + "</button>\n  </td>\n</tr>\n<tr>\n  <td></td>\n  <td>\n        <span class=\"edit-exam\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editableXml") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":10},"end":{"line":36,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"editable") : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":10},"end":{"line":45,"column":21}}})) != null ? stack1 : "")
    + "          <a class=\"js-remove-exam-button remove-exam-button\" data-exam-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"examUuid") || (depth0 != null ? lookupProperty(depth0,"examUuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"examUuid","hash":{},"data":data,"loc":{"start":{"line":46,"column":78},"end":{"line":46,"column":90}}}) : helper)))
    + "\" data-exam-title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":46,"column":109},"end":{"line":46,"column":118}}}) : helper)))
    + "\" data-i18n=\"sa.remove_exam\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"sa.remove_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":147},"end":{"line":46,"column":169}}}))
    + "</a>\n        </span>\n    "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"valid") : depth0),{"name":"unless","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":4},"end":{"line":48,"column":127}}})) != null ? stack1 : "")
    + "\n\n  </td>\n  <td colspan=\"3\">\n        <span class=\"password\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"password") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":10},"end":{"line":58,"column":17}}})) != null ? stack1 : "")
    + "        </span>\n  </td>\n</tr>\n";
},"useData":true});