var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"notification-popup\">\n    <h4>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.held_remove_exam_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":41}}}))
    + "</h4>\n    <form class=\"button-container\">\n        <button class=\"js-cancel-remove action-link\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":53},"end":{"line":4,"column":70}}}))
    + "</button>\n        <button class=\"js-proceed-remove action-link default-action\" data-exam-uuid=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":5,"column":85},"end":{"line":5,"column":93}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.held_remove_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":95},"end":{"line":5,"column":122}}}))
    + "</button>\n    </form>\n</div>";
},"useData":true});