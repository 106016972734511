import $ from 'jquery'
import utils from './utils'
import * as languageSelection from './language-selection'
import bannerRoleT from '../templates/page-banner-role.hbs'
import bannerLeftT from '../templates/banner-left.hbs'

export function setup(cb) {
  $('#pagebanner .banner-left').empty().append(bannerLeftT())
  addLogoutHandler()
  languageSelection.ensureSetupAndShowCurrentLanguage(cb)
}
export function showEmailAndLogout(emailOrName) {
  showEmailOrNameAndLogout(emailOrName)
}
export function loadAndShowEmailOrNameAndLogout() {
  utils.bacon.getJson('/kurko-api/user').onValue(userData => {
    if (userData.user && userData.user.firstName && userData.user.lastName) {
      showEmailOrNameAndLogout(`${userData.user.firstName} ${userData.user.lastName}`)
    } else {
      showEmailOrNameAndLogout(userData.userName)
    }
  })
}

export function showUserRoles(roles) {
  const $roleInformationDiv = $('.role-information')

  $roleInformationDiv.empty()
  if (roles.length === 1) {
    const schoolSpan = $('<span></span>').text(roles[0].schoolName).attr('data-school-id', roles[0].schoolId)
    $roleInformationDiv.append(schoolSpan)
  } else {
    $roleInformationDiv.append($(bannerRoleT(roles)))
  }
}

export function getRoleChangesE() {
  return $('.role-information').asEventStream('change', '#role-selection')
}

export function resetUI() {
  $('#pagebanner .logged-in-user').hide()
  $('#pagebanner .user-email').text('')
  $('#vetuma-logout').hide()
}

function showEmailOrNameAndLogout(text) {
  $('#pagebanner .user-email').text(text)
  $('#vetuma-logout').show()
  showLoggedInUserUI()
}

function showLoggedInUserUI() {
  $('#pagebanner .logged-in-user').css('display', 'inline-block')
}

function addLogoutHandler() {
  $('#pagebanner')
    .asEventStream('click', '.logout')
    .onValue(() => window.location.assign('/kurko-api/user/logout'))
}
