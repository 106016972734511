var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"notification-popup\">\n  <h4>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.remove_exam_title","",depth0,{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":42}}}))
    + "</h4>\n  <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.remove_exam_description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":5},"end":{"line":3,"column":39}}}))
    + "</p>\n  <form class=\"button-container\">\n    <button class=\"js-cancel-remove action-link\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":49},"end":{"line":5,"column":66}}}))
    + "</button>\n    <button class=\"js-proceed-remove action-link default-action\" data-exam-uuid=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":6,"column":81},"end":{"line":6,"column":89}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.remove_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":91},"end":{"line":6,"column":113}}}))
    + "</button>\n  </form>\n</div>\n";
},"useData":true});