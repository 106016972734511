var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"error-box error-box--block\" data-i18n=\"[html]sa.too_many_exams_warning\">\n  "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"sa.too_many_exams_warning",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":2},"end":{"line":28,"column":35}}}))
    + "\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"deletionDate") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":42,"column":6},"end":{"line":46,"column":13}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./deleted-exams-table-row.hbs"),depth0,{"name":"../templates/deleted-exams-table-row","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./available-exams-table-row.hbs"),depth0,{"name":"../templates/available-exams-table-row","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 data-i18n=\"sa.exams_tab\" class=\"exam-list-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exams_tab",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":53},"end":{"line":1,"column":73}}}))
    + "</h1>\n<div class=\"instructions-wrapper\">\n    <ul class=\"instructions-list\">\n        <li>\n            <a href=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exam_execution_instructions_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":21},"end":{"line":5,"column":63}}}))
    + "\" target=\"_blank\"\n               data-i18n=\"sa.exam_execution_instructions_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exam_execution_instructions_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":64},"end":{"line":6,"column":108}}}))
    + "</a>\n            <span data-i18n=\"sa.exam_execution_instructions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exam_execution_instructions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":61},"end":{"line":7,"column":99}}}))
    + "</span>\n        </li>\n        <li data-i18n=\"[html]sa.exam_execution_instructions_help\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exam_execution_instructions_help",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":66},"end":{"line":9,"column":109}}}))
    + "</li>\n    </ul>\n</div>\n<div class=\"exam-buttons-wrapper\">\n    <div>\n        <div>\n            <button id=\"js-add-xml-exam-event\" class=\"big-sa-button bertta-button\" data-i18n=\"sa.create_exam_mex\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.create_exam_mex",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":114},"end":{"line":16,"column":42}}}))
    + "</button>\n            <a href=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.bertta_instruction_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":21},"end":{"line":17,"column":54}}}))
    + "\" target=\"_blank\"\n               data-i18n=\"sa.bertta_instruction_label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.bertta_instruction_label",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":55},"end":{"line":19,"column":51}}}))
    + "</a>\n        </div>\n    </div>\n    <div id=\"exam-import-container\"></div>\n</div>\n<h3><span data-i18n=\"sa.exams\" class=\"exam-table-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":56},"end":{"line":24,"column":72}}}))
    + "</span> (<span class=\"js-exam-total\"></span>)</h3>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tooManyExams") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":0},"end":{"line":30,"column":7}}})) != null ? stack1 : "")
    + "<input type=\"checkbox\" id=\"show-deleted-exam\" class=\"show-deleted-input\"/> <label for=\"show-deleted-exam\" class=\"show-deleted-label\" data-i18n=\"sa.show_deleted\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.show_deleted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":161},"end":{"line":31,"column":184}}}))
    + "</label>\n<table id=\"available-exams\">\n  <thead>\n  <th data-i18n=\"sa.date\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":26},"end":{"line":34,"column":41}}}))
    + "</th>\n  <th data-i18n=\"sa.held_exam_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.held_exam_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":36},"end":{"line":35,"column":61}}}))
    + "</th>\n  <th data-i18n=\"sa.giving_exam\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.giving_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":33},"end":{"line":36,"column":55}}}))
    + "</th>\n  <th data-i18n=\"[html]sa.reusing_exam\">"
    + ((stack1 = lookupProperty(helpers,"t").call(alias1,"sa.reusing_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":40},"end":{"line":37,"column":65}}})) != null ? stack1 : "")
    + "</th>\n  <th></th>\n  </thead>\n  <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"exams") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":2},"end":{"line":47,"column":11}}})) != null ? stack1 : "")
    + "  </tbody>\n</table>\n";
},"usePartial":true,"useData":true});