const $ = require('jquery')
window.$ = $
window.jQuery = $
require('baconjs')
require('@digabi/jquery-caret')
require('jquery-ui/ui/effect')
require('jquery-ui/ui/effects/effect-highlight')

if (window.MathJax) {
  window.MathJax.Hub.Config({
    extensions: ['tex2jax.js', 'asciimath2jax.js'],
    jax: ['input/TeX', 'input/AsciiMath', 'output/SVG'],
    tex2jax: {
      preview: ['ladataan/laddas'],
      inlineMath: [['\\(', '\\)']],
      displayMath: [['\\[', '\\]']],
      processEscapes: true
    },
    asciimath2jax: {
      preview: ['ladataan/laddas'],
      delimiters: [['`', '`']]
    },
    SVG: {
      font: 'STIX-Web'
    },
    TeX: {
      extensions: ['AMSmath.js', 'AMSsymbols.js', 'noErrors.js', 'noUndefined.js']
    },
    showMathMenu: false,
    showMathMenuMSIE: false,
    skipStartupTypeset: true,
    messageStyle: 'none'
  })

  window.MathJax.Hub.Configured()
}
