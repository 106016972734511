var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"js-answers-uploaded-succesfully\" data-i18n=\"sa.answers_uploaded\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"sa.answers_uploaded",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":81},"end":{"line":3,"column":108}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"uploadedExams") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":10,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"exam-title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":39}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDuplicate") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":8,"column":13}}})) != null ? stack1 : "")
    + "      </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"error-notice\" data-i18n=\"sa.answers_uploaded_already\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"sa.answers_uploaded_already",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":75},"end":{"line":7,"column":110}}}))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"js-answers-uploaded-to-deleted\" data-i18n=\"sa.answers_uploaded_to_deleted\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.answers_uploaded_to_deleted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":91},"end":{"line":14,"column":129}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"deletedTitles") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":17,"column":13}}})) != null ? stack1 : "")
    + "    <br/>\n    <div class=\"js-answers-uploaded-to-deleted\" data-i18n=\"[html]sa.answers_uploaded_to_deleted_instructions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.answers_uploaded_to_deleted_instructions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":110},"end":{"line":19,"column":161}}}))
    + "</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"exam-title\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"answers-uploaded-notification clear-when-switching-tab\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"uploadedExams") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":11,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"deletedTitles") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":2},"end":{"line":20,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"answers-visibility-info\" data-i18n=\"sa.answers_visibility_info\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"sa.answers_visibility_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":78},"end":{"line":21,"column":112}}}))
    + "</div>\n</div>\n";
},"useData":true});