var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"left-section\">\n  <img src=\"" + require("../img/teacher_with_laptop.png") + "\" width=\"240\" height=\"240\">\n</div>\n\n<form id=\"start-registration\" class=\"inputform\" novalidate>\n  <h1 data-i18n=\"registration.welcome\"></h1>\n\n  <p data-i18n=\"registration.registration_prompt\"></p>\n  <h2 class=\"forgot-password-title\" data-i18n=\"registration.forgot_password\"></h2>\n  <p data-i18n=\"registration.forgot_password_prompt\"></p>\n  <label>\n    <h2 class=\"field-description\" data-i18n=\"registration.email\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"registration.email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":65},"end":{"line":12,"column":91}}}))
    + "</h2>\n    <input type=\"email\" class=\"text-input\" name=\"email\" maxlength=\"254\"/>\n  </label>\n  <div class=\"buttons\">\n    <input type=\"submit\" class=\"submit-button sa-button-medium\" data-i18n=\"[value]registration.submit_button\"/> <span id=\"sending-feedback\"></span>\n  </div>\n  <p data-i18n=\"registration.request_email_prompt\"></p>\n</form>\n";
},"useData":true});